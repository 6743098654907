import request from "@/utils/request";

class Apis {
  /**
   * 搜索家谱
   */
  articleIndex(data) {
    return request({
      method: "POST",
      url: "article/index",
      data,
    });
  }

  articleCategory(data) {
    return request({
      method: "POST",
      url: "article_cat/index",
      data,
    });
  }

  /**
   * 搜索家谱
   */
  articleDetail(data) {
    return request({
      method: "POST",
      url: "article/detail",
      data,
    });
  }

  articleAdd(data) {
    return request({
      method: "POST",
      url: "article/create",
      data,
    });
  }

  articleDel(data) {
    return request({
      method: "POST",
      url: "article/del",
      data,
    });
  }

  articleAbout(data) {
    return request({
      method: "POST",
      url: "article/aboutus",
      data,
    });
  }

  /**
   * 搜索家谱
   */
  courseIndex(data) {
    return request({
      method: "POST",
      url: "course/index",
      data,
    });
  }

  courseCategory(data) {
    return request({
      method: "POST",
      url: "course_cat/index",
      data,
    });
  }

  courseCategoryDetail(data) {
    return request({
      method: "POST",
      url: "course_cat/detail",
      data,
    });
  }

  /**
   * 搜索家谱
   */
  courseDetail(data) {
    return request({
      method: "POST",
      url: "course/detail",
      data,
    });
  }

  /**
   * 搜索家谱
   */
  courseDetailUrl(params) {
    return request({
      method: "POST",
      url: "course/detail_url",
      params,
    });
  }

  /**
   * 搜索家谱
   */
  banner(data) {
    return request({
      method: "POST",
      url: "banner/index",
      data,
    });
  }
}

export default new Apis();
